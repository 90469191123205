import * as React from 'react';
import { useState } from 'react';
import * as API from '../../Utils/API';
import { htmlIf, maybeHtml } from "../../Utils/HTML";
import { CopilotProfileProps } from './CopilotProfile';
import { DismissibleModal } from '../../Components/Modal';
import { Copilot, copilotMessagesUrl } from '../../Types/Copilot';
import Perspective from '../../Types/Perspective';
import { SignupButton } from '../../Utils/LoginButtons';
import AvatarUpload from '../../Components/AvatarUpload';

const messagesPath = "/messages"

function selectCopilotPath(copilot: Copilot) {
  return `/agent/new-request?copilot_individual_id=${copilot.id}`
}

export const ReadOnly = (props: CopilotProfileProps) => {
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [message, setMessage] = useState('');

  function sendMessage() {
    setIsSendingMessage(true);
    const body = { copilotIndividualId: props.copilot.id, message: message }
    API.post("messages_send_copilot_cold_message_path", body).then(function (result) {
      if (result['error']) {
      } else {
        window.location.href = messagesPath
      }
      setShowMessageModal(false);
      setIsSendingMessage(false);
    })
  }

  const ViewMessageModal = () => {
    return (
      <DismissibleModal
        title={<h4>Send a message</h4>}
        body={
          <div>
            <div className="">{`Enter the message you wish to send to ${props.copilot.preferredName}:`}</div>
            <textarea className="form-control mt-2"
              placeholder="Enter a message…"
              rows={4}
              value={message}
              onChange={(event) => setMessage(event.target.value)}
            />
          </div>
        }
        footer={
          <>
            <button className="btn btn-outline-danger w-100 w-sm-auto mb-2 mb-sm-0"
              onClick={() => setShowMessageModal(false)}
              disabled={isSendingMessage}
            >
              Cancel
            </button>
            <button className="btn btn-primary w-100 w-sm-auto ms-sm-2"
              onClick={() => sendMessage()}
              disabled={isSendingMessage}
            >
              Send
            </button>
          </>
        }
        onDismiss={() => setShowMessageModal(false)}
      />
    )
  }

  return (
    <div className="card rounded-2 pb-3">
      {htmlIf(showMessageModal, ViewMessageModal())}
      <div className="card-img-top bg-accent rounded-top" style={{height: '7rem'}}>
        <div className="mt-3 me-3">
          {htmlIf((props.copilot.averageRating !== null && props.copilot.numRatings > 1 && props.perspective != Perspective.Anonymous),
            <div className="text-dark d-flex align-items-center justify-content-end">
              <i className="ai-star me-1"/>
              {props.copilot.averageRating?.toFixed(1)}
              <span className="text-secondary ms-1">({props.copilot.numRatings} reviews)</span>
            </div>
          )}
        </div>
      </div>
      {ViewContent(props,
        <>
          {htmlIf(props.perspective === Perspective.Anonymous,
            <SignupButton className="btn btn-outline-primary" buttonText="Work with this CoPilot" />
          )}
          {htmlIf(props.perspective === Perspective.Agent,
            <div className="">
              { props.hasExistingMessages && false
              ? <a className="btn btn-outline-secondary" href={copilotMessagesUrl(props.copilot)}>
                  Messages
                </a>
              : props.preRequestMessagingAllowed
                ? <button className="btn btn-outline-primary" onClick={() => setShowMessageModal(true)}>
                    Message
                  </button>
                : null
              }
              <a className="btn btn-primary ms-2 px-5" href={selectCopilotPath(props.copilot)}>
                Hire
              </a>
          </div>
          )}
        </>
      )}
    </div>
  )
}

const ViewContent = (props: CopilotProfileProps, buttons: React.ReactElement, showActiveBadge?: boolean, showAvatarEdit?: boolean) => {
  return (
    <div>
      <div className="d-none d-md-block">
        <div className="row align-items-end">
          <div className="col-auto mt-n1 ms-2">
            { showAvatarEdit
            ? <AvatarUpload
                avatarUrl={props.copilot.imageUrl}
                savePathName='api_copilot_set_avatar_path'
                removePathName='api_copilot_remove_avatar_path'
                sizeClass='xxl'
                isLogo={false}
                avatarClass={'border-white border border-4'}
                avatarStyle={{marginTop: '-5rem'}}
              />
            : <div className="avatar avatar-xxl" style={{marginTop: '-5rem'}}>
                <img className="avatar-img rounded-circle border-white border border-4" src={props.copilot.imageUrl}/>
              </div>
            }
          </div>
          <div className="col me-3 pt-3 d-flex justify-content-between align-items-center">
            <div>
              <div className="d-flex align-items-center">
                <div className="fs-xl fw-bold text-dark">{props.copilot.preferredName}</div>
                {maybeHtml(showActiveBadge, () => (
                  props.copilot.isOpenToWork
                  ? <div className="badge bg-success ms-2">Active: Profile Visible</div>
                  : <div className="badge bg-danger ms-2">Inactive: Profile Not Visible</div>
                ))}
              </div>
              <div className="fs-md">{props.copilot.headline}</div>
            </div>
            {buttons}
          </div>
        </div>
      </div>
      <div className="d-md-none text-center">
        {/* Note: Manually overriding the width and height here — our avatar-xxl class forces a smaller avatar-xxl on narrow screens. */}
        <div className="avatar avatar-xxl" style={{marginTop: '-5rem', width: '8rem', height: '8rem'}}>
          <img className="avatar-img rounded-circle border-white border border-4" src={props.copilot.imageUrl}/>
        </div>
        <div className="mt-2 mt-md-0">{buttons}</div>
        <div className="mt-2">
          <div className="fs-xl fw-bold text-dark">{props.copilot.preferredName}</div>
          <div className="fs-md">{props.copilot.headline}</div>
        </div>
      </div>
    </div>
  )
}

export const Edit = (props: CopilotProfileProps, onUpdate: (CopilotProfileProps) => void) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [copiedProfileUrl, setCopiedProfileUrl] = useState(false);

  const [preferredName, setPreferredName] = useState(props.copilot.preferredName);
  const [pronouns, setPronouns] = useState(props.copilot.pronouns);
  const [headline, setHeadline] = useState(props.copilot.headline);
  const [isOpenToWork, setIsOpenToWork] = useState(props.copilot.isOpenToWork);

  function saveBasicInfo() {
    setIsSaving(true);

    const postBody = {
      preferredName: preferredName,
      pronouns: pronouns,
      headline: headline,
      isOpenToWork: isOpenToWork
    }

    API.post("api_copilot_update_basic_info_path", postBody).then(function (result) {
      onUpdate(result);
      setIsEditing(false);
      setIsSaving(false);
    })
  }

  const CopyProfileLinkButton = () => {
    const handleCopy = async () => {
      try {
        await navigator.clipboard.writeText(props.copilot.profileUrl);
        setCopiedProfileUrl(true);
        setTimeout(() => setCopiedProfileUrl(false), 500); // Reset after 0.5 seconds
      } catch (err) {
        console.error("Failed to copy:", err);
      }
    };

    return (
      <button onClick={handleCopy} className={`btn ${copiedProfileUrl ? 'btn-light text-dark' : 'btn-primary'}`}>
        { copiedProfileUrl
        ? 'Copied!'
        : <>
            <i className="ai-copy me-1" />
            Copy profile link
          </>
        }
      </button>
    );
  }

  const ViewModal = () => (
    <DismissibleModal
      dialogClass='modal-xl'
      title={<h4>Basic Info</h4>}
      body={
        <div className="">
          <div className="mt-2">
            <h5 className="mb-1">Preferred Name</h5>
            <input
              onChange={(e) => setPreferredName(e.target.value)}
              className="form-control"
              placeholder='Enter your name as it should be displayed to other users…'
              value={preferredName}
            />
          </div>
          <div className="mt-2">
            <h5 className="mb-1">Pronouns</h5>
            <input
              onChange={(e) => setPronouns(e.target.value)}
              className="form-control"
              value={pronouns}
            />
          </div>
          <div className="mt-2">
            <h5 className="mb-1">Headline</h5>
            <input
              onChange={(e) => setHeadline(e.target.value)}
              className="form-control"
              placeholder='What’s the first thing you want clients to know about you?'
              value={headline}
            />
          </div>
          <div className="mt-3 d-flex align-items-center">
            <div className="mt-1 form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                checked={isOpenToWork}
                onChange={() => setIsOpenToWork(!isOpenToWork)}
              />
            </div>
            <div className="ms-2">
              <h5 className="mb-1">Profile Visibility</h5>
              <div className="fs-md">
                { isOpenToWork
                ? "Your profile is currently visible to agents and new work can be submitted to you."
                : "Your profile is hidden from agents — you will not receive email notifications or direct requests for new work. An 'Out of Office' status will be displayed for anyone you are currently working with."
                }
              </div>
            </div>
          </div>
        </div>
      }
      footer={
        <div>
          <button className="btn btn-primary px-4 t--save-about" onClick={saveBasicInfo} disabled={isSaving}>Save</button>
        </div>
      }
      onDismiss={() => setIsEditing(false)}
    />
  )

  return (
    <div className="card rounded-2 pb-3">
      {htmlIf(isEditing, ViewModal())}
      <div className="card-img-top bg-accent rounded-top" style={{height: '7rem'}}>
        <div className="mt-3 me-3 text-end">
          <CopyProfileLinkButton />
        </div>
      </div>
      {ViewContent(props,
        <>
          <button className="btn btn-link p-0 t--edit-basic-info" onClick={() => setIsEditing(true)}>
            <i className="ai-edit me-1" />
            Edit
          </button>
        </>
      , true, true)}
    </div>
  )
}
