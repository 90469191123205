import { User } from './User';
import { LanguageCode } from '../Utils/Language';
import { Tag } from './Tag';

export type Copilot =
  { id: number
  , userId: number
  , createdAt: string
  , active: boolean
  , validForPayment: boolean
  , isOpenToWork: boolean
  , bio: string
  , headline: string
  , location: string
  , preferredName: string
  , pronouns: string
  , hourlyRateCents: number
  , tags: Tag[]
  , workStyles: CopilotWorkStyle[]
  , portfolioItems: CopilotPortfolioItem[]
  , imageUrl: string
  , favoritedByCurrentAgent: boolean
  , averageRating?: number
  , numRatings: number
  , jobsInProgress: number
  , jobsCompleted: number
  , lifetimeEarningsCents: number
  , languageCodes: LanguageCode[]
  , lastSeenAt: string
  , profileUrl: string
  , sortScore: number
  , user?: User
  }

export type CopilotFunFact =
  { question: string
  , answer: string
  , emoji: string
  }

export function copilotProfileUrl(copilot: Copilot) {
  return `/agent/copilots/profile/${copilot.id}`
}

export function copilotMessagesUrl(copilot: Copilot) {
  if (copilot) {
    return `/agent/messages?other_user_id=${copilot.userId}`
  } else {
    return "/agent/messages"
  }
}

export type CopilotWorkStyle =
  { id: number
  , name: string
  }

export type CopilotPortfolioItem =
  { id: number
  , title: string
  , fileUrl: string
  , isVisible: boolean
  }
