import * as React from 'react';
import { useState } from 'react';
import * as API from '../Utils/API.js';
import { Copilot } from '../Types/Copilot';
import { HourlyContract, ContractStatus, HourlyContractOffer, ContractOfferStatus, displayContractBudgetRangeUSD } from '../Types/Hourly';
import { CopilotRequestStatusBadge } from '../Common/RequestStatusBadge';
import HourlyTimesheet from '../Common/HourlyTimesheets';
import HourlyReceipts from '../Common/HourlyReceipts';
import Perspective from '../Types/Perspective';
import { DismissibleModal } from '../Components/Modal';
import { htmlIf, formatMultiParagraphString, maybeHtml, formatMarkdown } from '../Utils/HTML';
import { formatUSDWithCents } from '../Utils/Currency';
import { CustomerRating } from '../Types/CustomerRating';
import { ReadOnlyRating } from '../Agent/Components/Ratings';
import { displayScope } from '../Types/RequestScope';
import { StandalonePricingGraph } from '../Components/RheostatSlider';

type Props =
  { contract: HourlyContract
  , currentCopilot: Copilot
  , backUrl: string
  , negotiationEnabled: boolean
  }

enum Modal
  { NoModal
  , DeclineRequestModal
  , ClaimRequestModal
  , CompleteRequestModal
  , LogWorkModal
  , SubmitOfferModal
  , EditOfferModal
  , RescindOfferModal
  }

function messagesPathForCustomerUserId(customerUserId: number) {
  return `/copilot/messages?other_user_id=${customerUserId}`
}

const copilotPaymentPercentage = 0.8

const RequestDetails = (props: Props) => {
  const [contract, setContract] = useState(props.contract);
  const [modal, setModal] = useState(Modal.NoModal);
  const [showModalErrors, setShowModalErrors] = useState(false);
  const [showOfferExampleText, setShowOfferExampleText] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [workDescription, setWorkDescription] = useState('');
  const [workDurationMinutes, setWorkDurationMinutes] = useState(0);

  const [offerHourlyRateCents, setOfferHourlyRateCents] = useState(0);
  const [offerDescription, setOfferDescription] = useState('');

  const [declineMessage, setDeclineMessage] = useState('');

  function declineRequest() {
    setIsSubmitting(true);
    const body = { contractId: contract.id, message: declineMessage }
    API.post("copilot_requests_decline_hourly_contract_path", body).then(function (result) {
      if (result['error']) {
      } else {
        setContract(result['contract'])
      }
      setModal(Modal.NoModal)
      setIsSubmitting(false);
    })
  }

  function claimRequest() {
    setIsSubmitting(true);
    const body = { contractId: contract.id, copilotId: props.currentCopilot.id }
    API.post("copilot_requests_claim_hourly_contract_path", body).then(function (result) {
      if (result['error']) {
      } else {
        setContract(result['contract'])
      }
      setModal(Modal.NoModal)
      setIsSubmitting(false);
    })
  }

  function completeRequest() {
    setIsSubmitting(true);
    const body = { contractId: contract.id }
    API.post("copilot_requests_complete_hourly_contract_path", body).then(function (result) {
      if (result['error']) {
      } else {
        setContract(result['contract'])
      }
      setModal(Modal.NoModal)
      setIsSubmitting(false);
    })
  }

  function logWork() {
    if (workDescription.length > 0 && workDurationMinutes > 0) {
      setIsSubmitting(true);
      const body =
        { contractId: contract.id
        , description: workDescription
        , durationMinutes: workDurationMinutes
        }
      API.post("copilot_requests_log_hourly_work_path", body).then(function (result) {
        if (result['error']) {
        } else {
          setContract(result['contract']);
        }
        setModal(Modal.NoModal);
        setWorkDescription('');
        setWorkDurationMinutes(0);
        setIsSubmitting(false);
      })
    } else {
      setShowModalErrors(true);
    }
  }

  function submitOffer() {
    if (offerDescription.length > 0 && offerHourlyRateCents > 0) {
      setIsSubmitting(true);
      const body =
        { contractId: contract.id
        , copilotId: props.currentCopilot.id
        , hourlyRateCents: offerHourlyRateCents
        , description: offerDescription
        }
      API.post("copilot_requests_submit_hourly_contract_offer_path", body).then(function (result) {
        if (result['error']) {
        } else {
          setContract(result['contract']);
        }
        setModal(Modal.NoModal);
        setIsSubmitting(false);
        setOfferHourlyRateCents(0);
        setOfferDescription('');
      })
    } else {
      setShowModalErrors(true);
    }
  }

  function rescindOffer() {
    setIsSubmitting(true);
    const offer = contract.offers.find((offer) => offer.copilot.id === props.currentCopilot.id)

    const body = { contractOfferId: offer.id }
    API.post("copilot_requests_rescind_hourly_contract_offer_path", body).then(function (result) {
      if (result['error']) {
      } else {
        setContract(result['contract'])
      }
      setModal(Modal.NoModal)
      setIsSubmitting(false);
    })
  }

  function editOffer() {
    if (offerDescription.length > 0 && offerHourlyRateCents > 0) {
      setIsSubmitting(true);
      const offer = contract.offers.find((offer) => offer.copilot.id === props.currentCopilot.id)

      const body =
        { contractOfferId: offer.id
        , hourlyRateCents: offerHourlyRateCents
        , description: offerDescription
        }

      API.post("copilot_requests_edit_hourly_contract_offer_path", body).then(function (result) {
        if (result['error']) {
        } else {
          setContract(result['contract']);
        }
        setModal(Modal.NoModal);
        setIsSubmitting(false);
        setOfferHourlyRateCents(0);
        setOfferDescription('');
      })
    } else {
      setShowModalErrors(true);
    }
  }

  function formatDate(dateString: string):string {
    const date = new Date(dateString)
    return (
      date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      })
    )
  }

  const DeclineRequestButton = () => (
    <button className="btn btn-outline-danger px-3 ms-2" onClick={() => setModal(Modal.DeclineRequestModal)}>
      Decline Request
    </button>
  )

  const ClaimRequestButton = () => (
    <button className="btn btn-success px-3 ms-2" onClick={() => setModal(Modal.ClaimRequestModal)}>
      Claim Request
    </button>
  )

  const CompleteRequestButton = () => (
    <button className="btn btn-link text-danger px-3 me-1 t--complete-request" onClick={() => setModal(Modal.CompleteRequestModal)}>
      Close Request
    </button>
  )

  const LogWorkButton = () => (
    <button className="btn btn-primary px-3 ms-2" onClick={() => setModal(Modal.LogWorkModal)}>
      Log Work
    </button>
  )

  const ViewMessagesButton = () => (
    <a className="btn btn-outline-primary px-3 ms-2" href={messagesPathForCustomerUserId(contract.customerUserId)}>
      Messages
    </a>
  )

  const SubmitOfferButton = () => (
    <button className="btn btn-primary px-3 ms-2" onClick={() => setModal(Modal.SubmitOfferModal)}>
      Make an offer
    </button>
  )

  // We use this same body for the SubmitOffer and EditOffer modal, so no reason to repeat it
  const EnterOfferDetailsModalBody = ():React.ReactElement => (
    <div>
      <div className="mb-2">
        <div className="fs-md fw-semibold text-gray-800 mb-2">Offer price range: $0 - $100</div>
        <StandalonePricingGraph
          topBorderRadius={4}
          barCategoryGapPercentage={5}
          heightPx={75}
        />
      </div>
      <div className="row">
        <div className="col-md-6">
          <h6 className="fw-bold">Your offered hourly rate</h6>
          <div className="input-group align-items-center p-0">
            <div className="input-group-prepend ms-2">$</div>
            <input
              className="form-control t--hourly-rate" type="number"
              value={offerHourlyRateCents / 100} min={0} max={5000}
              onChange={(event) => setOfferHourlyRateCents(parseInt(event.target.value) * 100)}
            />
          </div>
        </div>
        <div className="col-md-6 mt-2 mt-md-0">
          <h6 className="fw-bold">Your take-home rate</h6>
          <input
            className="form-control"
            value={offerHourlyRateCents ? formatUSDWithCents(offerHourlyRateCents * copilotPaymentPercentage) : '$0'}
            disabled={true}
          />
          {/* </div> */}
        </div>
      </div>
      {htmlIf(showModalErrors && !(offerHourlyRateCents > 0),
          <div className="fs-sm mt-1 text-danger fw-bold">Please enter a rate.</div>
        )}
      <div className="mt-3">
        <h6 className="fw-bold">Message</h6>
        <textarea className="form-control"
          placeholder="Share a message with the agent to give them more information about your offer."
          rows={6}
          value={offerDescription}
          onChange={(event) => setOfferDescription(event.target.value)}
        />
        {htmlIf(showModalErrors && !offerDescription.length,
          <div className="fs-sm mt-1 text-danger fw-bold">Please enter a message.</div>
        )}
        <button className="btn btn-link ps-0 text-decoration-none"
          onClick={() => setShowOfferExampleText(!showOfferExampleText)}
        >Need help writing a great offer message?</button>
        {htmlIf(showOfferExampleText,
          <div className="mt-0 fs-sm">
            <div className="fw-bold">Here's an example:</div>
            Hi there,
            <br />
            I’d love to help you with this! I used to work as a travel agency assistant for 3 years and I’m very familiar with building in Travefy. I’ve also completed their online certification.  Please feel free to reach out with any questions and I look forward to hopefully working with you!
            <br />
            Cheers,
            Lucia
          </div>
        )}
      </div>
    </div>
  )

  const ViewModal = () => {
    switch(modal) {
      case Modal.NoModal:
        return null
      case Modal.DeclineRequestModal:
        return (
          <DismissibleModal
            title={<h4>Decline this request?</h4>}
            body={
              <div>
                <div className="">{`Please share a brief message with ${contract.customerName} to explain why you’re declining their request:`}</div>
                <textarea className="form-control mt-2"
                  placeholder="Share some context on why you aren’t interested in this request…"
                  rows={4}
                  value={declineMessage}
                  onChange={(event) => setDeclineMessage(event.target.value)}
                />
              </div>
            }
            footer={
              <>
                <button className="btn btn-outline-primary w-100 w-sm-auto mb-2 mb-sm-0"
                  onClick={() => setModal(Modal.NoModal)}
                  disabled={isSubmitting}
                >
                  No, not yet
                </button>
                <button className="btn btn-danger w-100 w-sm-auto ms-sm-2"
                  onClick={() => declineRequest()}
                  disabled={isSubmitting}
                >
                  Yes, decline it
                </button>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      case Modal.ClaimRequestModal:
        return (
          <DismissibleModal
            title={<h4>Before you claim this request…</h4>}
            body={
              <div>
                <ol>
                  <li>Do you understand the scope of work required?</li>
                  <li>Do you have experience with all of the technologies that are involved in the task?</li>
                  <li>Does your availability align with the client’s needs?</li>
                </ol>
                <div>Be sure you have the answers you need before claiming the request!</div>
              </div>
            }
            footer={
              <>
                <button className="btn btn-outline-danger w-100 w-sm-auto mb-2 mb-sm-0"
                  onClick={() => setModal(Modal.NoModal)}
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
                <button className="btn btn-success w-100 w-sm-auto ms-sm-2"
                  onClick={() => claimRequest()}
                  disabled={isSubmitting}
                >
                  Yes, claim it!
                </button>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      case Modal.LogWorkModal:
        return (
          <DismissibleModal
            title={<h4>Log Work</h4>}
            body={
              <div>
                <div className="">
                  <h6 className="fw-bold">Description</h6>
                  <textarea className="form-control"
                    placeholder="Enter a brief description of the work you’re logging…"
                    rows={5}
                    value={workDescription}
                    onChange={(event) => setWorkDescription(event.target.value)}
                  />
                  {htmlIf(showModalErrors && !workDescription.length,
                    <div className="fs-sm mt-1 text-danger fw-bold">Please enter a description.</div>
                  )}
                </div>
                <div className="mt-3">
                  <h6 className="fw-bold">Duration (minutes)</h6>
                  <input className="form-control"
                    type="number"
                    value={workDurationMinutes}
                    onChange={(event) => setWorkDurationMinutes(parseInt(event.target.value))}
                    placeholder="Enter a whole number of minutes…"
                  />
                  {htmlIf(showModalErrors && !(workDurationMinutes > 0),
                    <div className="fs-sm mt-1 text-danger fw-bold">Please enter a number greater than 0.</div>
                  )}
                </div>
              </div>
            }
            footer={
              <>
                <button className="btn btn-outline-danger w-100 w-sm-auto mb-2 mb-sm-0"
                  onClick={() => setModal(Modal.NoModal)}
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
                <button className="btn btn-primary w-100 w-sm-auto ms-sm-2"
                  onClick={() => logWork()}
                  disabled={isSubmitting}
                >
                  Save
                </button>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      case Modal.SubmitOfferModal:
        return (
          <DismissibleModal
            title={
              <>
                <h4 className="mb-1">Make an offer</h4>
              </>
            }
            body={EnterOfferDetailsModalBody()}
            footer={
              <>
                <button className="btn btn-outline-danger w-100 w-sm-auto mb-2 mb-sm-0"
                  onClick={() => setModal(Modal.NoModal)}
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
                <button className="btn btn-primary w-100 w-sm-auto ms-sm-2"
                  onClick={submitOffer}
                  disabled={isSubmitting}
                >
                  Submit offer
                </button>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      case Modal.EditOfferModal:
        return (
          <DismissibleModal
            title={
              <>
                <h4 className="mb-1">Edit offer</h4>
              </>
            }
            body={EnterOfferDetailsModalBody()}
            footer={
              <>
                <button className="btn btn-outline-danger w-100 w-sm-auto mb-2 mb-sm-0"
                  onClick={() => setModal(Modal.NoModal)}
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
                <button className="btn btn-primary w-100 w-sm-auto ms-sm-2"
                  onClick={editOffer}
                  disabled={isSubmitting}
                >
                  Save offer
                </button>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      case Modal.RescindOfferModal:
        return (
          <DismissibleModal
            title={<h4>Rescind your offer?</h4>}
            body={<div>Are you sure you want to rescind your offer to work on this request?</div>}
            footer={
              <>
                <button className="btn btn-outline-primary w-100 w-sm-auto mb-2 mb-sm-0"
                  onClick={() => setModal(Modal.NoModal)}
                  disabled={isSubmitting}
                >
                  No, never mind
                </button>
                <button className="btn btn-danger w-100 w-sm-auto ms-sm-2"
                  onClick={() => rescindOffer()}
                  disabled={isSubmitting}
                >
                  Yes, rescind my offer
                </button>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      case Modal.CompleteRequestModal:
        return (
          <DismissibleModal
            title={<h4>Close this request?</h4>}
            body={
              <>
                <div className="fw-semibold mb-1 text-gray-900">Are you sure you want to close this request?</div>
                <div>
                  You will no longer be able to log any additional
                  work and your client will be billed for any remaining unpaid hours.
                </div>
              </>
            }
            footer={
              <>
                <button className="btn btn-primary w-100 w-sm-auto mb-2 mb-sm-0"
                  onClick={() => setModal(Modal.NoModal)}
                  disabled={isSubmitting}
                >
                  No, keep it
                </button>
                <button className="btn btn-outline-danger w-100 w-sm-auto ms-sm-2 t--confirm-complete-request"
                  onClick={() => completeRequest()}
                  disabled={isSubmitting}
                >
                  Yes, close it
                </button>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      default:
        return null
    }
  }

  const ViewRequestHeader = () => {
    const dateText = `
      Submitted on ${formatDate(contract.createdAt)}
      ${contract.status === ContractStatus.WITHDRAWN && !!contract.withdrawnAt
        ? `• Cancelled on ${formatDate(contract.withdrawnAt)}`
        : ''}
      ${contract.status === ContractStatus.COMPLETED && !!contract.completedAt
        ? `• Completed on ${formatDate(contract.completedAt)}`
        : ''}
    `;

    // Used for displaying a banner suggesting that the CoPilot may be a good fit for this request based on common tags.
    const commonTags = contract.tags.filter(copilotTag =>
      props.currentCopilot.tags.some(tag => tag.id === copilotTag.id)
    );

    const commonTagsString = (() => {
      const names = commonTags.map(tag => tag.name);
      if (names.length === 0) return ""; // Handle empty array
      if (names.length === 1) return names[0]; // Single item
      if (names.length === 2) return names.join(" and "); // Two items
      // Three or more items
      return `${names.slice(0, -1).join(", ")}, and ${names[names.length - 1]}`;
    })();

    return (
      <div className="card mt-3 rounded-1">
        <div className="card-body p-3">
          <h4 className="mb-0">{contract.title}</h4>
          <div className="mt-2 text-dark">
            <div className="d-flex align-items-center mb-2">
              <CopilotRequestStatusBadge status={contract.status} isDirectRequest={contract.copilot?.id > 0}/>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="ai-user fs-xxl me-2" />
              {contract.customerName}
              {htmlIf(contract.isAgentVirtuoso,
                <div className="ms-2 badge bg-secondary text-white">Virtuoso Member</div>
              )}
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="ai-calendar fs-xxl me-2" />
              {dateText}
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="ai-card fs-xxl me-2" />
              { contract.hourlyRateCents === 0
                ? `Awaiting offers ${displayContractBudgetRangeUSD(contract)}`
                : `${formatUSDWithCents(contract.copilotHourlyRateCents)}/hour`
              }
            </div>
            {maybeHtml(contract.scope, (scope) => (
              <div className="d-flex align-items-center mb-2">
                <i className="ai-briefcase fs-xxl me-2" />
                {displayScope(scope)}
              </div>
            ))}
            {htmlIf(contract.tags?.length > 0,
              <div className="d-flex align-items-center mb-1">
                <i className="ai-tag fs-xxl me-2 mb-1" />
                {contract.tags?.map((tag) =>
                  <div key={`tag-${tag.id}`} className="badge bg-accent badge-sm text-dark me-1 mb-1 d-inline-block">{tag.name}</div>
                )}
              </div>
            )}
          </div>
        </div>
        {htmlIf(commonTags.length > 0 && contract.status === ContractStatus.SUBMITTED,
          <div className="px-3 py-2 rounded-1 rounded-top-0 border-top bg-accent d-flex align-items-center">
            <img src='/img/dashboard/star.png' width={40} className="me-2" />
            You might be a good fit for this job given your experience with
            <span className="fw-semibold"> {commonTagsString}</span>.
          </div>
        )}
      </div>
    )
  }

  function prepareToEditOffer(offer: HourlyContractOffer) {
    setOfferDescription(offer.description);
    setOfferHourlyRateCents(offer.hourlyRateCents);
    setModal(Modal.EditOfferModal)
  }

  const ViewOffer = (offer: HourlyContractOffer) => (
    <div className="card rounded-1 mt-3">
      <div className="card-body p-3">
        <h5 className="d-flex align-items-center justify-content-between mb-2">
          <div>Your offer</div>
          <button className="btn btn-link p-0 text-decoration-none text-primary" onClick={() => prepareToEditOffer(offer)}>
            <i className="ai-edit-alt text-primary me-1" />
            Edit
          </button>
        </h5>
        <div>{formatMultiParagraphString(offer.description)}</div>
        <div className="row mt-3">
          <div className="col-auto">
            <div className="fs-sm">Offered rate</div>
            <div className="fs-lg fw-semibold text-dark mt-1">{formatUSDWithCents(offer.hourlyRateCents)}</div>
          </div>
          <div className="col ms-3">
            <div className="fs-sm">Your take-home rate</div>
            <div className="fs-lg fw-semibold text-dark mt-1">{formatUSDWithCents(offer.hourlyRateCents * copilotPaymentPercentage)}</div>
          </div>
        </div>
        <button className="btn btn-outline-danger mt-2" onClick={() => setModal(Modal.RescindOfferModal)}>
          Rescind Offer
        </button>
      </div>
    </div>
  )

  const ViewRating = (customerRating: CustomerRating) => (
    <div className="card rounded-1 mt-3">
      <div className="card-body p-3">
        <h5 className="mb-0">Rating and feedback</h5>
        <div className="row mt-3">
          <div className="col-6 col-md-3">
            <ReadOnlyRating rating={customerRating.rating} starClass={'fs-xl fw-bold text-primary'}/>
            <div className="fs-md fw-semibold text-gray-900">Overall Rating</div>
          </div>
          {maybeHtml(customerRating.qualityRating, (quality) => (
            <div className="col-6 col-md-3">
              <ReadOnlyRating rating={quality} starClass={'fs-md text-primary'}/>
              <div className="fs-md text-gray-900">Quality of Work</div>
            </div>
          ))}
          {maybeHtml(customerRating.communicationRating, (communication) => (
            <div className="col-6 col-md-3">
              <ReadOnlyRating rating={communication} starClass={'fs-md text-primary'}/>
              <div className="fs-md text-gray-900">Communication</div>
            </div>
          ))}
          {maybeHtml(customerRating.timeRating, (time) => (
            <div className="col-6 col-md-3">
              <ReadOnlyRating rating={time} starClass={'fs-md text-primary'}/>
              <div className="fs-md text-gray-900">Timeliness</div>
            </div>
          ))}
        </div>
        {maybeHtml(customerRating.ratingComment, (publicFeedback) => (
          <div className="mt-3">
            <div className="fs-md fw-semibold text-gray-900">Public rating comment</div>
            <div className="fs-md">{publicFeedback}</div>
          </div>
        ))}
        {maybeHtml(customerRating.copilotFeedback, (copilotFeedback) => (
          <div className="mt-3">
            <div className="fs-md fw-semibold text-gray-900">Private feedback</div>
            <div className="fs-md">{copilotFeedback}</div>
          </div>
        ))}
      </div>
    </div>
  )

  return (
    <div>
      {ViewModal()}
      <div className="row align-items-end">
        <div className="col-auto d-flex align-items-center">
          <a href={props.backUrl} className="text-decoration-none align-items-center d-flex">
            <i className="ai-arrow-left me-1"/>
            <i className="ai-grid me-1"/>
            Back
          </a>
        </div>
        <div className="col d-flex justify-content-end mt-1 mt-md-0">
          {/* We only show the Claim button when a contract is submitted directly to a copilot or a rate is specified. */}
          {htmlIf((
            contract.status === ContractStatus.SUBMITTED &&
            ( contract.copilot?.id === props.currentCopilot.id || contract.hourlyRateCents > 0)
          ),
            <>
              <ClaimRequestButton />
            </>
          )}
          {/* We only show the Decline button when a contract is submitted directly to a copilot. */}
          {htmlIf(contract.status === ContractStatus.SUBMITTED && contract.copilot?.id === props.currentCopilot.id,
            <DeclineRequestButton />
          )}
          {/* If the agent wanted to accept offers (indicated by an hourly rate of 0¢), we show the
              Make an Offer button instead, provided that the Copilot has not already submitted an offer.
           */}
          {htmlIf((
            contract.status === ContractStatus.SUBMITTED &&
            contract.copilot === null &&
            contract.hourlyRateCents === 0 &&
            contract.offers.every((offer) => offer.copilot.id !== props.currentCopilot.id)
          ),
            <>
            { props.negotiationEnabled
            ? <SubmitOfferButton />
            : <ClaimRequestButton />
            }
            </>
          )}
          {htmlIf(contract.status === ContractStatus.CLAIMED || contract.status === ContractStatus.COMPLETED,
            <ViewMessagesButton />
          )}
          {htmlIf(contract.status === ContractStatus.CLAIMED,
            <>
              <LogWorkButton />
              <CompleteRequestButton />
            </>
          )}
        </div>
      </div>
      {ViewRequestHeader()}
      <div className="card rounded-1 mt-3">
        <div className="card-body p-3">
          <h5 className="mb-2">About the job</h5>
          <div>{formatMarkdown(contract.description)}</div>
        </div>
      </div>
      {htmlIf(contract.status === ContractStatus.SUBMITTED,
        <>
        {maybeHtml(contract.offers.find((offer) => offer.copilot.id === props.currentCopilot.id), (offer) => (
          <>
            { offer.status === ContractOfferStatus.RESCINDED
            ? <>
                <div className="card rounded-1 card-body p-3 mt-3">You rescinded your offer on this request.</div>
              </>
            : offer.status === ContractOfferStatus.DECLINED_MANUAL || offer.status === ContractOfferStatus.DECLINED_AUTO
            ? <div className="card rounded-1 card-body p-3 mt-3">Your offer for this request was not accepted.</div>
            : ViewOffer(offer)
            }
          </>
        ))}
        </>
      )}
      {htmlIf(contract.status === ContractStatus.CLAIMED || contract.status === ContractStatus.COMPLETED || contract.status === ContractStatus.WITHDRAWN,
        <>
          <div className="card rounded-1 card-body p-3 mt-3">
            <HourlyTimesheet
              contract={contract}
              perspective={Perspective.Copilot}
              setParentContract={setContract}
              triggerLogWorkModal={() => setModal(Modal.LogWorkModal)}
            />
          </div>
          <div className="card rounded-1 card-body p-3 mt-3">
            {HourlyReceipts(contract, Perspective.Copilot)}
          </div>
        </>
      )}
      {maybeHtml(contract.customerRating, (rating) => (
        ViewRating(rating)
      ))}
    </div>
  )
};


export default RequestDetails;
