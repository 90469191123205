import * as React from 'react';
import { useState, useRef } from 'react';
import { CopilotWorkStyle } from '../../Types/Copilot';
import Perspective from '../../Types/Perspective';
import { htmlIf } from '../../Utils/HTML';
import EventGrid from '../../Livestream/EventGrid';
import PackageCard from '../PackageCard';
import * as Header from './Header';
import * as About from './About';
import * as Skills from './Skills';
import * as CompletedJobs from './CompletedJobs';
import * as WorkHistory from './WorkHistory';
import * as FunFacts from './FunFacts';
import { CopilotProfileProps } from './CopilotProfile.js';
import { Tag } from '../../Types/Tag';

type Props =
  { profileProps: CopilotProfileProps
  , allTags: Tag[]
  , allWorkStyles: CopilotWorkStyle[]
  , settingsFunFacts: FunFacts.AllFunFacts
  }

export const CopilotProfile = (props: Props) => {
  const [profileState, setProfileState] = useState(props.profileProps);

  function updateProfileState(newProfileProps: CopilotProfileProps) {
    setProfileState(newProfileProps);
  }

  // Courtesy of ChatGPT
  const ViewPortfolio = () => {
    const scrollRef = useRef(null);

    const scroll = (direction) => {
      if (scrollRef.current) {
        const scrollAmount = direction === 'left' ? -300 : 300; // Adjust scroll amount as needed
        scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      }
    };

    return (
      <div className="card mt-3 rounded-2">
        <div className="card-body py-3">
          <div className="fs-xl fw-bold text-dark">Featured Work</div>
          <div className="position-relative mt-3">
            <button onClick={() => scroll('left')} className="position-absolute top-50 start-0 translate-middle-y bg-accent py-1 border-0 z-1 rounded-2">
              <i className="ai-arrow-left fs-xxl" />
            </button>
            <div className="overflow-auto d-flex" ref={scrollRef}
              style={{ scrollBehavior: 'smooth', padding: '0 50px' }} // Padding for button space
            >
              {profileState.copilot.portfolioItems.filter((item) => (item.isVisible === true)).map((item) => (
                <div className={`col-12 col-sm-9 col-md-6 col-lg-4 col-xl-3 flex-shrink-0 me-2`} key={item.id}>
                  <a href={item.fileUrl} target='_blank' className="text-decoration-none d-flex h-100">
                    <div className="card card-body p-3 d-flex justify-content-center align-items-center fw-semibold fs-lg text-primary cursor-pointer">
                      {item.title}
                      <i className="ai-external-link mt-auto pt-1" />
                    </div>
                  </a>
                </div>
              ))}
            </div>
            <button onClick={() => scroll('right')} className="position-absolute top-50 end-0 translate-middle-y bg-accent py-1 border-0 z-1 rounded-2">
              <i className="ai-arrow-right fs-xxl" />
            </button>
          </div>
        </div>
      </div>
    );
  };

  const ViewCopilotPackages = () => (
    <div className="card mt-3 rounded-2">
      <div className="card-body py-3">
        <div className="fs-xl fw-bold text-dark">Packages</div>
        <div className="row">
          {profileState.copilotPackages.map((pkg) => (
            <div className="col-md-6 col-lg-4 mt-3" key={pkg.id}>
              {PackageCard(pkg)}
            </div>
          ))}
        </div>
      </div>
    </div>
  )

  return (
    <div>
      {Header.Edit(profileState, updateProfileState)}
      {About.Edit(profileState, updateProfileState, props.allWorkStyles)}
      {htmlIf(profileState.perspective != Perspective.Anonymous,
        CompletedJobs.Edit(profileState)
      )}
      {Skills.Edit(profileState, updateProfileState, props.allTags)}
      {WorkHistory.Edit(profileState)}
      {htmlIf(profileState.copilot.portfolioItems.filter((item) => (item.isVisible === true)).length > 0,
        <ViewPortfolio />
      )}
      {htmlIf(profileState.copilotPackages.length > 0,
        <ViewCopilotPackages />
      )}
      {htmlIf(profileState.livestreamEvents.length > 0,
        <div className="card mt-3 rounded-2">
          <div className="card-body py-3">
            <div className="fs-xl fw-bold text-dark mb-2">Livestreams by {profileState.copilot.preferredName}</div>
            <EventGrid events={profileState.livestreamEvents.slice(0,3)} perspective={profileState.perspective} targetBlank={true}/>
          </div>
        </div>
      )}
      {FunFacts.Edit(profileState, updateProfileState, props.settingsFunFacts)}
    </div>
  )
}

export default CopilotProfile;
