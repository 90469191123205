import * as React from 'react';
import { CopilotProfileProps } from './CopilotProfile';

export const ReadOnly = (props: CopilotProfileProps) => (
  ViewContent(props)
)

function formatEarnings(amountCents: number):string {
  let dollars = amountCents / 100
  if (dollars < 100) {
    return "< $100"
  } else if (dollars < 1000) {
    let rounded = Math.floor(dollars / 100) * 100;
    return `$${rounded}+`
  } else if (dollars > 1000) {
    let rounded = Math.floor(dollars / 1000);
    return `$${rounded}K+`
  } else {
    return "--"
  }
}

const ViewContent = (props: CopilotProfileProps) => (
  <div className="card mt-3 rounded-2">
    <div className="card-body py-3">
      <div className="row align-items-center">
        <div className="col-md-4 d-flex align-items-center">
          <div className="avatar avatar-md">
            <div className="avatar-title bg-accent rounded-1 text-primary h3">
              <i className="ai-tool" />
            </div>
          </div>
          <div className="ms-2">
            <div className="fs-lg fw-bold text-dark">{props.copilot.jobsInProgress}</div>
            {props.copilot.jobsInProgress === 1 ? 'Job' : 'Jobs'} in Progress
          </div>
        </div>
        <div className="col-md-4 d-flex align-items-center mt-2 mt-md-0">
          <div className="avatar avatar-md">
            <div className="avatar-title bg-accent rounded-1 text-primary h3">
              <i className="ai-circle-check" />
            </div>
          </div>
          <div className="ms-2">
            <div className="fs-lg fw-bold text-dark">{props.copilot.jobsCompleted}</div>
            {props.copilot.jobsCompleted === 1 ? 'Job' : 'Jobs'} Completed
          </div>
        </div>
        <div className="col-md-4 d-flex align-items-center mt-2 mt-md-0">
          <div className="avatar avatar-md">
            <div className="avatar-title bg-accent rounded-1 text-primary h3">
              <i className="ai-dollar" />
            </div>
          </div>
          <div className="ms-2">
            <div className="fs-lg fw-bold text-dark">{formatEarnings(props.copilot.lifetimeEarningsCents)}</div>
            Total Earnings
          </div>
        </div>
      </div>
    </div>
  </div>
)

export const Edit = (props: CopilotProfileProps) => {
  return (
    ViewContent(props)
  )
}
