import * as React from 'react';
import { useState } from 'react';
import * as API from '../../Utils/API';
import { htmlIf } from "../../Utils/HTML";
import { CopilotProfileProps } from './CopilotProfile';
import { DismissibleModal } from '../../Components/Modal';

export const ReadOnly = (props: CopilotProfileProps) => (
  <div className="card mt-3 rounded-2">
    <div className="card-body py-3">
      <div className="fs-xl fw-bold text-dark">Fun Facts</div>
      {ViewContent(props)}
    </div>
  </div>
)

const ViewContent = (props: CopilotProfileProps) => (
  <div className="mt-2">
    {props.funFacts.map((funFact) => (
      <div key={funFact.emoji} className="row g-0 no-gutters align-items-center mb-2">
        <div className="col-auto justify-content-center">
          <div className="avatar avatar-md me-2">
            <div className="avatar-title bg-accent rounded-1 lead">{funFact.emoji}</div>
          </div>
        </div>
        <div className="col">
          <div className="fs-sm">{funFact.question}</div>
          <div className="fs-md text-dark">{funFact.answer}</div>
        </div>
      </div>
    ))}
  </div>
)

export type AllFunFacts =
  { advice: string
  , bucketListDestinations: string
  , favoriteAirline: string
  , favoriteDestination: string
  , favoriteFood: string
  , favoriteHotel: string
  , firstJob: string
  , fiveWordDescription: string
  , hobbies: string
  , destinationExpertise: string
  }

export const Edit = (props: CopilotProfileProps, onUpdate: (CopilotProfileProps) => void, funFacts: AllFunFacts) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [state, setState] = useState<AllFunFacts>(funFacts);

  function saveFunFacts() {
    const postBody = {
      copilotIndividualId: props.copilot.id,
      advice: state.advice,
      bucketListDestinations: state.bucketListDestinations,
      favoriteAirline: state.favoriteAirline,
      favoriteDestination: state.favoriteDestination,
      favoriteFood: state.favoriteFood,
      favoriteHotel: state.favoriteHotel,
      firstJob: state.firstJob,
      fiveWordDescription: state.fiveWordDescription,
      hobbies: state.hobbies,
      destinationExpertise: state.destinationExpertise
    }

    API.post("api_copilot_update_fun_facts_path", postBody).then(function (result) {
      onUpdate(result);
      setIsEditing(false);
      setIsSaving(false);
    })
  }

  const handleChange = (key: keyof AllFunFacts, value: string) => {
    setState(prevState => ({ ...prevState, [key]: value }));
  };

  const renderFunFact = (emoji: string, question: string, value?: string, key?: keyof AllFunFacts) => (
    <div className="mb-2 d-flex flex-column gap-1">
      <label className="fw-semibold">
        {question} {emoji}
      </label>
      <input
        type="text"
        maxLength={140}
        value={value || ''}
        onChange={(event) => handleChange(key!, event.target.value)}
        className="form-control"
      />
    </div>
  );

  const ViewModal = () => (
    <DismissibleModal
      dialogClass='modal-xl'
      title={<h4>Fun Facts</h4>}
      body={
        <div className="">
          {renderFunFact('💬', 'Describe yourself in five words', state.fiveWordDescription, 'fiveWordDescription')}
          {renderFunFact('💭', 'Best piece of advice', state.advice, 'advice')}
          {renderFunFact('🧑🏼‍💼', 'First job', state.firstJob, 'firstJob')}
          {renderFunFact('🌎', 'Favorite destination', state.favoriteDestination, 'favoriteDestination')}
          {renderFunFact('🍽', 'Favorite food', state.favoriteFood, 'favoriteFood')}
          {renderFunFact('🗺', 'Bucket list destinations', state.bucketListDestinations, 'bucketListDestinations')}
          {renderFunFact('🏨', 'Favorite hotel', state.favoriteHotel, 'favoriteHotel')}
          {renderFunFact('✈️', 'Favorite airline', state.favoriteAirline, 'favoriteAirline')}
          {renderFunFact('⭐', 'Hobbies', state.hobbies, 'hobbies')}
          {renderFunFact('🌴', 'Destination Expertise', state.destinationExpertise, 'destinationExpertise')}
        </div>
      }
      footer={
        <div>
          <button className="btn btn-primary px-4 t--save-fun-facts" onClick={saveFunFacts} disabled={isSaving}>Save</button>
        </div>
      }
      onDismiss={() => setIsEditing(false)}
    />
  )

  return (
    <div className="card mt-3 rounded-2">
      {htmlIf(isEditing, ViewModal())}
      <div className="card-body py-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="fs-xl fw-bold text-dark">Fun Facts</div>
          <button className="btn btn-link p-0 t--edit-fun-facts" onClick={() => setIsEditing(true)}>
            <i className="ai-edit me-1" />
            Edit
          </button>
        </div>
        {props.funFacts.length > 0
        ? ViewContent(props)
        : <div className="text-center py-2 mt-2 text-dark">
            <img src='/img/dashboard/star.png' width={64} className="" />
            <div className="mt-1 fs-md fw-semibold">Add fun facts</div>
            <div className="fs-md">Share something unique about yourself and make your profile stand out!</div>
          </div>
        }
      </div>
    </div>
  )
}
