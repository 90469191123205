import * as React from 'react';
import { maybeHtml } from "../../Utils/HTML";
import { CopilotProfileProps } from './CopilotProfile';
import PaginatedItems from '../../Components/PaginatedItems';
import { formatUSDWithCents } from '../../Utils/Currency';
import { ReadOnlyRating } from '../../Agent/Components/Ratings';

export const ReadOnly = (props: CopilotProfileProps) => (
  <div className="card mt-3 rounded-2">
    <div className="card-body py-3">
      <div className="fs-xl fw-bold text-dark">Work history</div>
      {ViewContent(props)}
    </div>
  </div>
)

const ViewContent = (props: CopilotProfileProps) => (
  <div className="mt-2">
    <PaginatedItems
      allItems={props.workHistory}
      numItemsPerPage={5}
      renderItem={(history) => (
        <div className="py-2 border-bottom" key={`${history.title}-${history.dateDescription}`}>
          <div className="d-flex align-items-center justify-content-between mb-1">
            <div className="fs-md fw-semibold text-dark">{history.title}</div>
            <div className="">{formatUSDWithCents(history.subtotalCents)}</div>
          </div>
          <div className="d-flex align-items-center mb-1">
            {maybeHtml(history.rating, (rating) => (
              <div className="d-flex align-items-end">
                <ReadOnlyRating rating={rating} starClass='fs-xxl mb-0 text-primary'/>
                <div className="fs-md text-primary ps-1">{rating}</div>
              </div>
            ))}
          </div>
          { history.ratingComment
          ? <div className="fs-md text-dark">“{history.ratingComment}”</div>
          : <div className="fs-md text-gray-700">No feedback provided</div>
          }
          <div className="fs-md text-gray-600 mt-1">{history.dateDescription}</div>
        </div>
      )}
    />
  </div>
)

export const Edit = (props: CopilotProfileProps) => {
  return (
    <div className="card mt-3 rounded-2">
      <div className="card-body py-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="fs-xl fw-bold text-dark">Work history</div>
        </div>
        {props.workHistory.length > 0
        ? ViewContent(props)
        : <div className="text-center py-2 mt-2 text-dark">
            <img src='/img/dashboard/receipt.png' width={64} className="" />
            <div className="mt-1 fs-md fw-semibold">No items</div>
            <div className="fs-md">Complete jobs on Lucia to build your work history.</div>
          </div>
        }
      </div>
    </div>
  )
}
