import * as React from 'react';
import * as API from '../Utils/API';
import { useState } from 'react';
import { DismissibleModal } from './Modal';
import { htmlIf, maybeHtml } from '../Utils/HTML';

type Props = {
  /** If supplied, the current URL of the avatar in quesiton. If blank, we assume no avatar currently exists */
  avatarUrl?: string;

  /** Rails path name (must be in client_side_endpoints) where we handle saving the uploaded avatar */
  savePathName: string;

  /** Rails path name (must be in client_side_endpoints) where we handle removing an existing avatar */
  removePathName: string;

  /** One of 'xs', 'sm', 'lg', 'xl', or 'xxl' (see avatar.scss file for avatar size class definitions) */
  sizeClass: string;

  /**
   * In some cases, we use this avatar upload functionality for company logos;
   * in these cases, we modify the language to be more appropriate for the logo use case.
  */
  isLogo: boolean;

  /** Additional classes, if any, to apply to the avatar. */
  avatarClass?: string;

  /** If we want to apply a particular style to the avatar div, we can add that here. */
  avatarStyle?: {}
}

enum Modal
  { NoModal
  , EditAvatarModal
  , RemoveAvatarModal
  , ConfirmAvatarModal
  }

const AvatarUpload = (props: Props) => {
  const [modal, setModal] = useState(Modal.NoModal);

  const [avatarFile, setAvatarFile] = useState<File>();
  const [avatarPreviewUrl, setAvatarPreviewUrl] = useState(props.avatarUrl);

  function removeAvatar() {
    API.post(props.removePathName, {}).then(function (result) {
      if (result['redirectUrl']) {
        window.location.href = result['redirectUrl']
      }
    })
  }

  function setAvatar() {
    let formData = new FormData();
    formData.append("avatar", avatarFile);
    API.postFormData(props.savePathName, formData).then(function (result) {
      if (result['redirectUrl']) {
        window.location.href = result['redirectUrl']
      }
    })
  }

  function openFileUpload() {
    document.getElementById('avatar-input').click();
  }

  function handleFileUploaded(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files[0]

    setAvatarFile(file);

    const reader = new FileReader();
    reader.onload = () => {
      setAvatarPreviewUrl(reader.result as string);
      setModal(Modal.ConfirmAvatarModal);
    };
    reader.readAsDataURL(file);
  }

  const ViewModal = () => {
    switch (modal) {
      case Modal.NoModal:
        return null
      case Modal.RemoveAvatarModal:
        return (
          <DismissibleModal
            title={<h4>{props.isLogo ? 'Remove Logo' : 'Remove profile picture'}</h4>}
            body={
              <>
                <div className="mb-2">
                  Are you sure you want to remove your current profile picture?
                </div>
                <div className="">
                  <button className="btn btn-danger w-100 mb-2" onClick={removeAvatar}>Remove</button>
                  <button className="btn btn-outline-primary w-100" onClick={() => setModal(Modal.NoModal)}>Keep</button>
                </div>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      case Modal.EditAvatarModal:
        return (
          <DismissibleModal
            title={<h4>{props.isLogo ? 'Upload Logo' : 'Upload profile picture'}</h4>}
            body={
              <>
                <div className="text-center mb-2">
                  { props.isLogo
                  ? 'Upload your logo to make your profile look more professional!'
                  : 'A high-quality profile picture will make your profile look more professional!'
                  }
                </div>
                {maybeHtml(props.avatarUrl, (avatarUrl) => (
                  <div className="text-center">
                    <div className={`avatar avatar-${props.sizeClass}`}>
                      <img className="avatar-img rounded-circle img-fluid" src={avatarUrl} id="avatar-preview" />
                    </div>
                    <div className="text-secondary mt-2">Preview</div>
                  </div>
                ))}
                {htmlIf(!props.isLogo,
                  <ul className="mt-3 text-start">
                    <li>Use a high-resolution image</li>
                    <li>Pick a picture where you’re the only person in it</li>
                    <li>Make sure your face is in the center of the photo</li>
                  </ul>
                )}
                <div className="text-center">
                  <button className="btn btn-primary px-3" onClick={openFileUpload}>
                    Upload a new photo
                  </button>
                </div>
                <input className="d-none" type="file" id="avatar-input" onChange={handleFileUploaded} />
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      case Modal.ConfirmAvatarModal:
        return (
          <DismissibleModal
            title={<h4>{props.isLogo ? 'Confirm Logo' : 'Confirm profile picture'}</h4>}
            body={
              <>
                <div className="text-center">
                  <div className={`avatar avatar-${props.sizeClass}`}>
                    <img className="avatar-img rounded-circle img-fluid" id="avatar-confirm-preview" src={avatarPreviewUrl} />
                  </div>
                  <div className="text-secondary mt-2">
                    Preview
                  </div>
                </div>
                <div className="fs-lg fw-semibold text-center mt-3">
                  Save this photo as your {props.isLogo ? 'logo' : 'profile picture'}?
                </div>
                <div className="mt-3">
                  <button className="btn btn-success w-100 mb-2" onClick={setAvatar}>Save</button>
                  <button className="btn btn-outline-danger w-100" onClick={() => setModal(Modal.NoModal)}>Cancel</button>
                </div>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
    }
  }

  return (
    <>
      <ViewModal />
      { props.avatarUrl
      ? <>
          <div className="dropdown text-center">
            <div className={`avatar avatar-${props.sizeClass} cursor-pointer rounded-circle overflow-hidden ${props.avatarClass}`} style={props.avatarStyle} data-bs-toggle="dropdown" aria-expanded="false">
              <img src={props.avatarUrl} id="avatar-preview" className="avatar-img rounded-circle"/>
              <div className="avatar-overlay fs-sm">
                <i className="ai-camera me-1" />
                Edit
              </div>
            </div>
            <div className="dropdown-menu" aria-labelledby="edit-avatar-button">
              <a className="dropdown-item" href="#" onClick={() => setModal(Modal.EditAvatarModal)}>
                Upload a photo…
              </a>
              <a className="dropdown-item" href="#" onClick={() => setModal(Modal.RemoveAvatarModal)}>
                Remove photo
              </a>
            </div>
          </div>
        </>
      : <div className={`avatar avatar-${props.sizeClass}`} style={props.avatarStyle}>
          <div className="avatar-title rounded-circle overflow-hidden bg-secondary cursor-pointer" onClick={() => setModal(Modal.EditAvatarModal)}>
            <div className="text-center text-dark fs-lg d-flex align-items-center">
              <i className="ai-camera me-1" />
              Upload
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default AvatarUpload;
