import * as React from 'react';
import { useState } from 'react';
import * as API from '../../Utils/API';
import { htmlIf } from "../../Utils/HTML";
import { CopilotProfileProps } from './CopilotProfile';
import { DismissibleModal } from '../../Components/Modal';
import Select from 'react-select';
import { reactSelectItemToTag, Tag, tagToReactSelectItem } from '../../Types/Tag';

export const ReadOnly = (props: CopilotProfileProps) => (
  <div className="card mt-3 rounded-2">
    <div className="card-body py-3">
      <div className="fs-xl fw-bold text-dark">Skills</div>
      {ViewContent(props)}
    </div>
  </div>
)

const ViewContent = (props: CopilotProfileProps) => (
  <div className="mt-2">
    {htmlIf(props.copilot.tags.length > 0,
      <>
        {props.copilot.tags.sort((a, b) => a.name.localeCompare(b.name)).map((tag) => (
          <div key={`Tag-${tag.id}`} className="badge bg-accent badge-sm text-dark me-1 d-inline-block mt-1">
            {tag.name}
          </div>
        ))}
      </>
    )}
  </div>
)

type ReactSelectOption =
  { value: any
  , label: string
  }

export const Edit = (props: CopilotProfileProps, onUpdate: (CopilotProfileProps) => void, allTags: Tag[]) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [selectedTagItems, setSelectedTagItems] = useState(
    props.copilot.tags.sort((a, b) => a.name.localeCompare(b.name)).map((tag) => (tagToReactSelectItem(tag)))
  );

  function saveSkills() {
    setIsSaving(true);
    const tags: Tag[] = selectedTagItems.map((item) => (reactSelectItemToTag(item, allTags)));

    const postBody = {
      tags: tags,
    }

    API.post("api_copilot_update_skills_path", postBody).then(function (result) {
      onUpdate(result);
      setIsEditing(false)
      setIsSaving(false);
    })
  }

  const ViewModal = () => (
    <DismissibleModal
      dialogClass='modal-xl'
      title={<h4>Skills</h4>}
      body={
        <div className="">
          <div className="mt-2">
            <Select
              className="rounded"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              placeholder={'Select skills…'}
              isMulti={true}
              options={allTags.map((tag) => (tagToReactSelectItem(tag)))}
              onChange={(newTags: ReactSelectOption[]) => setSelectedTagItems(newTags)}
              value={selectedTagItems}
            />
          </div>
        </div>
      }
      footer={
        <div>
          <button className="btn btn-primary px-4 t--save-skills" onClick={saveSkills} disabled={isSaving}>Save</button>
        </div>
      }
      onDismiss={() => setIsEditing(false)}
    />
  )

  return (
    <div className="card mt-3 rounded-2">
      {htmlIf(isEditing, ViewModal())}
      <div className="card-body py-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="fs-xl fw-bold text-dark">Skills</div>
          <button className="btn btn-link p-0 t--edit-skills" onClick={() => setIsEditing(true)}>
            <i className="ai-edit me-1" />
            Edit
          </button>
        </div>
        {props.copilot.tags.length > 0
        ? ViewContent(props)
        : <div className="text-center py-2 mt-2 text-dark">
            <img src='/img/dashboard/tags.png' width={64} className="" />
            <div className="mt-1 fs-md fw-semibold">Add skills</div>
            <div className="fs-md">Highlight your strengths to stand out to potential clients.</div>
          </div>
        }
      </div>
    </div>
  )
}
