import * as React from 'react';
import { useState } from 'react';
import * as API from '../../Utils/API';
import { formatMultiParagraphString, htmlIf } from "../../Utils/HTML";
import { formatExchangeCurrencyWithoutCents, formatUSDWithCents } from '../../Utils/Currency';
import { CopilotProfileProps } from './CopilotProfile';
import * as Language from '../../Utils/Language';
import { lastSeenAtDescription } from '../../Utils/DateTime';
import { DismissibleModal } from '../../Components/Modal';
import { StandalonePricingGraph } from '../../Components/RheostatSlider';
import Select from 'react-select';
import { CopilotWorkStyle } from '../../Types/Copilot';

export const ReadOnly = (props: CopilotProfileProps) => (
  <div className="card mt-3 rounded-2">
    <div className="card-body py-3">
      <div className="fs-xl fw-bold text-dark">About</div>
      {ViewContent(props)}
    </div>
  </div>
)

const ViewContent = (props: CopilotProfileProps) => (
  <div>
    <div className="fs-md mt-2">{formatMultiParagraphString(props.copilot.bio)}</div>
    <div className="fs-lg fw-semibold text-dark mt-1"></div>
    {htmlIf((props.copilot.hourlyRateCents !== null && props.copilot.hourlyRateCents > 0),
      <div className="fs-lg fw-semibold text-dark mt-1">
        {formatExchangeCurrencyWithoutCents(props.displayCurrency, props.copilot.hourlyRateCents, props.exchangeRates)}/hour
      </div>
    )}
    <div className="mt-2">
      {htmlIf(props.copilot.location !== null,
        <div className="d-flex align-items-center">
          <i className="ai-map-pin fs-lg me-1" />
          {props.copilot.location}
        </div>
      )}
      {htmlIf(props.copilot.languageCodes.length > 0,
        <div className="d-flex align-items-center mt-1">
          <i className="ai-message fs-lg me-1" />
          <div>
            {props.copilot.languageCodes.map((code, index) => (
              <React.Fragment key={index}>
                {Language.name(code)}
                {index < props.copilot.languageCodes.length - 1 ? " | " : ""}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
      {htmlIf(props.copilot.pronouns !== null,
        <div className="d-flex align-items-center mt-1">
          <i className="ai-user fs-lg me-1" />
          {props.copilot.pronouns}
        </div>
      )}
      {htmlIf(props.copilot.workStyles.length > 0,
        <div className="d-flex align-items-center mt-1">
          <i className="ai-briefcase fs-lg me-1" />
          <div>
            { props.copilot.workStyles.length === 0
            ? 'This text should never show up, we just need a condition for an empty array! Grrr'
            : props.copilot.workStyles.length === 1
            ? `Open to ${props.copilot.workStyles[0].name.toLowerCase()} work`
            : props.copilot.workStyles.length === 2
            ? `Open to ${props.copilot.workStyles[0].name.toLowerCase()} and ${props.copilot.workStyles[1].name.toLowerCase()} work`
            : `Open to ${props.copilot.workStyles.slice(0, -1).map((item) => item.name.toLowerCase()).join(', ')}, and ${props.copilot.workStyles[props.copilot.workStyles.length - 1].name.toLowerCase()} work`
            }
          </div>
        </div>
      )}
      {htmlIf(props.copilot.lastSeenAt,
        <div className="d-flex align-items-center mt-1">
          <i className="ai-time me-1" />
          <div className="fs-md">{lastSeenAtDescription(props.copilot.lastSeenAt)}</div>
        </div>
      )}
    </div>
  </div>
)

const copilotPaymentPercentage = 0.8

type ReactSelectOption =
  { value: any
  , label: string
  }

export const Edit = (props: CopilotProfileProps, onUpdate: (CopilotProfileProps) => void, allWorkStyles: CopilotWorkStyle[]) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [bio, setBio] = useState(props.copilot.bio);
  const [hourlyRate, setHourlyRate] = useState(props.copilot.hourlyRateCents / 100);
  const [location, setLocation] = useState(props.copilot.location);
  const [selectedWorkStyleItems, setSelectedWorkStyleItems] = useState(
    props.copilot.workStyles.map(workStyle => ({ value: workStyle.id, label: workStyle.name }))
  );
  const [selectedLanguages, setSelectedLanguages] = useState(props.copilot.languageCodes.map((code) => Language.asSelectOption(code)));

  function saveAbout() {
    setIsSaving(true);
    const workStyles: CopilotWorkStyle[] = selectedWorkStyleItems.map((item) => ({ id: item.value, name: item.label }))

    const postBody = {
      hourlyRateCents: (Number(hourlyRate) * 100),
      bio: bio,
      workStyles: workStyles,
      location: location,
      languageCodes: selectedLanguages.map((languageItem: ReactSelectOption) => languageItem.value)
    }

    API.post("api_copilot_update_about_path", postBody).then(function (result) {
      onUpdate(result);
      setIsEditing(false);
      setIsSaving(false);
    })
  }

  const ViewModal = () => (
    <DismissibleModal
      dialogClass='modal-xl'
      title={<h4>About</h4>}
      body={
        <div className="">
          <div className="mt-2">
            <h5 className="mb-1">Bio (Pitch yourself!)</h5>
            <textarea
              onChange={(e) => setBio(e.target.value)}
              className="form-control"
              rows={8}
              placeholder='Enter your bio…'
              maxLength={5000}
              value={bio}
            />
          </div>
          <div className="mt-2">
            <div className="d-flex align-items-center mb-1">
              <h5 className="mb-0">Hourly Rate</h5>
              <div className="fs-sm ms-2 fst-italic">This is the default hourly rate that customers will pay when working with you.</div>
            </div>
            <div className="row">
              <div className="col-12 d-flex justify-content-center mb-2 py-1 bg-gray rounded-3">
                <div className="col-md-9 text-center">
                  <div className="fw-semibold text-decoration-underline">Distribution of hourly rates among Lucia CoPilots</div>
                  <div className="fs-md text-gray-700 mb-1">Lucia average hourly rate: $35</div>
                  <StandalonePricingGraph
                    topBorderRadius={6}
                    barCategoryGapPercentage={5}
                    heightPx={75}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <h6 className="fw-bold mb-1">Your public hourly rate</h6>
                <div className="input-group align-items-center p-0">
                  <div className="input-group-prepend ms-2">$</div>
                  <input
                    className="form-control t--hourly-rate" type="number"
                    value={hourlyRate} min={0} max={5000}
                    onChange={(event) => setHourlyRate(parseInt(event.target.value))}
                  />
                </div>
              </div>
              <div className="col-md-6 mt-2 mt-md-0">
                <h6 className="fw-bold mb-1">Your take-home rate</h6>
                <input
                  className="form-control"
                  value={hourlyRate ? formatUSDWithCents(hourlyRate * copilotPaymentPercentage * 100) : '$0'}
                  disabled={true}
                />
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className="mt-2">
            <h5 className="mb-1">Location</h5>
            <input
              onChange={(e) => setLocation(e.target.value)}
              className="form-control"
              value={location}
            />
          </div>
          <div className="mt-2">
            <h5 className="mb-1">What types of work are you open to?</h5>
            <Select
              className="rounded"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              isMulti={true}
              options={allWorkStyles.map((workStyle) => ({ label: workStyle.name, value: workStyle.id}))}
              onChange={(newWorkStyles: ReactSelectOption[]) => setSelectedWorkStyleItems(newWorkStyles)}
              value={selectedWorkStyleItems}
            />
          </div>
          <div className="mt-2">
            <h5 className="mb-1">Languages</h5>
            <Select
              className="rounded"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              isMulti={true}
              options={Language.allAsSelectOptions()}
              onChange={(newLanguages: ReactSelectOption[]) => setSelectedLanguages(newLanguages)}
              value={selectedLanguages}
              placeholder="Select any languages you speak fluently…"
            />
          </div>
        </div>
      }
      footer={
        <div>
          <button className="btn btn-primary px-4 t--save-about" onClick={saveAbout} disabled={isSaving}>Save</button>
        </div>
      }
      onDismiss={() => setIsEditing(false)}
    />
  )

  return (
    <div className="card mt-3 rounded-2">
      {htmlIf(isEditing, ViewModal())}
      <div className="card-body py-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="fs-xl fw-bold text-dark">About</div>
          <button className="btn btn-link p-0 t--edit-about" onClick={() => setIsEditing(true)}>
            <i className="ai-edit me-1" />
            Edit
          </button>
        </div>
        {props.copilot.bio?.length > 0
        ? ViewContent(props)
        : <div className="text-center py-2 mt-2 text-dark">
            <img src='/img/dashboard/user.png' width={64} className="" />
            <div className="mt-1 fs-md fw-semibold">Tell us about yourself</div>
            <div className="fs-md">Share your story and help clients get to know you better.</div>
          </div>
        }
      </div>
    </div>
  )
}
