import * as React from 'react';
import { useState, useRef } from 'react';
import * as API from '../../Utils/API.js';
import { Copilot, CopilotFunFact } from '../../Types/Copilot';
import { CustomerRating } from '../../Types/CustomerRating';
import { Package } from '../../Types/Package';
import Perspective from '../../Types/Perspective';
import { htmlIf } from '../../Utils/HTML';
import { SignupButton } from '../../Utils/LoginButtons';
import * as Livestream from '../../Types/Livestream';
import { CurrencyCode, ExchangeRate } from '../../Utils/Currency';
import EventGrid from '../../Livestream/EventGrid';
import PackageCard from '../PackageCard';
import * as Header from './Header';
import * as About from './About';
import * as Skills from './Skills';
import * as CompletedJobs from './CompletedJobs';
import * as WorkHistory from './WorkHistory'
import * as FunFacts from './FunFacts';

export type CopilotProfileProps =
  { copilot: Copilot
  , reviews: CustomerRating[]
  , copilotPackages: Package[]
  , livestreamEvents: Livestream.Event[]
  , funFacts: CopilotFunFact[]
  , workHistory: CopilotWorkHistory[]
  , perspective: Perspective
  , hasExistingMessages: boolean
  , preRequestMessagingAllowed: boolean
  , displayCurrency: CurrencyCode
  , exchangeRates: ExchangeRate
  }

// This is defined on the Ruby side in Copilot::Individual#work_history_client_json
type CopilotWorkHistory =
  { title: string
  , dateDescription: string
  , rating?: number
  , ratingComment?: string
  , subtotalCents: number
  }

const agentCopilotListPath = "/agent/copilots"

export const CopilotProfile = (props: CopilotProfileProps) => {
  // Courtesy of ChatGPT
  const ViewPortfolio = () => {
    const scrollRef = useRef(null);

    const scroll = (direction) => {
      if (scrollRef.current) {
        const scrollAmount = direction === 'left' ? -300 : 300; // Adjust scroll amount as needed
        scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      }
    };

    return (
      <div className="card mt-3 rounded-2">
        <div className="card-body py-3">
          <div className="fs-xl fw-bold text-dark">Featured Work</div>
          <div className="position-relative mt-3">
            <button onClick={() => scroll('left')} className="position-absolute top-50 start-0 translate-middle-y bg-accent py-1 border-0 z-1 rounded-2">
              <i className="ai-arrow-left fs-xxl" />
            </button>
            <div className="overflow-auto d-flex" ref={scrollRef}
              style={{ scrollBehavior: 'smooth', padding: '0 50px' }} // Padding for button space
            >
              {props.copilot.portfolioItems.filter((item) => (item.isVisible === true)).map((item) => (
                <div className={`col-12 col-sm-9 col-md-6 col-lg-4 col-xl-3 flex-shrink-0 me-2`} key={item.id}>
                  <a href={item.fileUrl} target='_blank' className="text-decoration-none d-flex h-100">
                    <div className="card card-body p-3 d-flex justify-content-center align-items-center fw-semibold fs-lg text-primary cursor-pointer">
                      {item.title}
                      <i className="ai-external-link mt-auto pt-1" />
                    </div>
                  </a>
                </div>
              ))}
            </div>
            <button onClick={() => scroll('right')} className="position-absolute top-50 end-0 translate-middle-y bg-accent py-1 border-0 z-1 rounded-2">
              <i className="ai-arrow-right fs-xxl" />
            </button>
          </div>
        </div>
      </div>
    );
  };

  const ViewCopilotPackages = () => (
    <div className="card mt-3 rounded-2">
      <div className="card-body py-3">
        <div className="fs-xl fw-bold text-dark">Packages</div>
        <div className="row">
          {props.copilotPackages.map((pkg) => (
            <div className="col-md-6 col-lg-4 mt-3" key={pkg.id}>
              {PackageCard(pkg)}
            </div>
          ))}
        </div>
      </div>
    </div>
  )

  const ViewReviews = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const visibleReviews = isExpanded ? props.reviews : props.reviews.slice(0, 3);

    const ViewSingleReview = (review: CustomerRating, showBorder: boolean) => (
      <div key={review.id} className={`pb-3 mb-2 ${showBorder ? 'border-bottom' : ''}`}>
        <div className="fs-md mb-1 fw-bold text-dark">{review.ratedByName}</div>
        <div className="d-flex align-items-center mb-1">
          {Array.from({ length: review.rating }, (_, index) => (
            <i key={index} className="ai-star-filled text-primary fs-lg" />
          ))}
          <div className="ms-1 fs-lg fw-bold text-primary">{review.rating}</div>
          <div className="ms-1 fs-sm">| {review.createdTimeAgo}</div>
        </div>
        <div className="fs-md">{review.ratingComment}</div>
      </div>
    )

    return (
      <div className="card mt-3 rounded-2">
        <div className="card-body py-3">
          <div className="fs-xl fw-bold text-dark mb-2">Reviews</div>
          {visibleReviews.map((review, index) => (
            ViewSingleReview(review, (index < visibleReviews.length - 1))
          ))}
          {props.reviews.length > 3 && !isExpanded && (
            <button className="btn btn-link fw-bold ps-0 pt-0 mt-n6" onClick={() => setIsExpanded(true)}>Show More</button>
          )}
        </div>
      </div>
    );
  }

  const ViewAnonymousSignUpCta = () => (
    <div className="card mt-3 rounded-2 bg-accent">
      <div className="card-body py-3">
        <h4 className="fw-bold">Want to work with {props.copilot.preferredName}?</h4>
        <div className="fs-md text-dark">Join Lucia and get started with a free trial!</div>
        <div className="mt-2">
          <SignupButton className="btn btn-primary" buttonText="Get started" />
        </div>
      </div>
    </div>
  )

  return (
    <div>
      {htmlIf(props.perspective != Perspective.Anonymous && props.perspective != Perspective.ViewOnly,
        <div className="col-auto d-flex align-items-center mb-2">
          <a href={agentCopilotListPath} className="text-decoration-none align-items-center d-flex">
            <i className="ai-arrow-left me-1"/>
            <i className="ai-user-group me-1"/>
            All CoPilots
          </a>
      </div>
      )}
      <Header.ReadOnly {...props} />
      <About.ReadOnly {...props} />
      {htmlIf(props.perspective != Perspective.Anonymous,
        CompletedJobs.ReadOnly(props)
      )}
      {htmlIf(props.perspective === Perspective.Anonymous,
        <ViewAnonymousSignUpCta />
      )}
      {htmlIf(props.copilot.tags.length > 0,
        Skills.ReadOnly(props)
      )}
      {htmlIf(props.workHistory.length > 0,
        WorkHistory.ReadOnly(props)
      )}
      {htmlIf(props.copilot.portfolioItems.filter((item) => (item.isVisible === true)).length > 0,
        <ViewPortfolio />
      )}
      {htmlIf(props.copilotPackages.length > 0,
        <ViewCopilotPackages />
      )}
      {htmlIf(props.livestreamEvents.length > 0,
        <div className="card mt-3 rounded-2">
          <div className="card-body py-3">
            <div className="fs-xl fw-bold text-dark mb-2">Livestreams by {props.copilot.preferredName}</div>
            <EventGrid events={props.livestreamEvents.slice(0,3)} perspective={props.perspective} targetBlank={true}/>
          </div>
        </div>
      )}
      {htmlIf(props.funFacts.length > 0,
        FunFacts.ReadOnly(props)
      )}
    </div>
  )
}

export default CopilotProfile;
