import * as React from 'react';
import { useState } from 'react';
import ReactPaginate from 'react-paginate';

type Props<ItemType> =
  { allItems: ItemType[]
  , numItemsPerPage: number
  , renderItem: (item: ItemType) => React.ReactElement
  }

// Allows for pagination of an array of ItemType, with a provided number of items per page and a per-item render function.
const PaginatedItems = <T,>({allItems, numItemsPerPage, renderItem}: Props<T>) => {
  // What item are we starting on?
  const [itemOffset, setItemOffset] = useState(0);

  // Which items should be displayed on the current page?
  const currentItems = allItems.slice(itemOffset, itemOffset + numItemsPerPage)

  // How many pages do we have in total?
  const pageCount = Math.ceil(allItems.length / numItemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * numItemsPerPage) % allItems.length;
    setItemOffset(newOffset);
  }

  return (
    <>
      { currentItems && currentItems.map((item) => (
        renderItem(item)
      ))
      }
      <div className="d-flex justify-content-end">
        <ReactPaginate
          className="react-paginate py-2 mb-0 fw-semibold text-primary"
          pageLinkClassName="text-primary"
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
}

export default PaginatedItems;
